<template>
    <v-card :class="$vuetify.theme.dark ? 'grey darken-4' : ''">
        <v-card-actions style="height: 64px;">
          <v-toolbar-title class="text-h5">
            Surat Permohonan Izin Cuti {{id}}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text outlined class="text-capitalize" depressed @click="CetakSurat">Cetak</v-btn>
          <v-btn text outlined class="text-capitalize" depressed @click="DialogLihatSuratCuti = false">Keluar</v-btn>
        </v-card-actions>

        <v-divider></v-divider>

        <v-card-text :class="$vuetify.theme.dark ? 'dark' : 'grey lighten-3'">
          <v-container class="fill-height" fluid>
            <v-row>
              <v-sheet outlined width="21cm" height="auto" class="ma-8 mx-auto elevation-15">
                <div id="cetakan" style="padding: 35px; width: 21cm; height: auto; margin: auto; font-family: 'Calibri', sans-serif; font-size: 14px;">
                  <!-- Kepala Surat Start -->
                  <div>
                    <table>
                      <tr>
                        <td style="text-align: center; after: float: left; width: 30%; padding-top: 2px; border-right: 1px solid; border-left: 1px solid; border-top: 1px solid; border-bottom: 1px solid;">
                          <img style="width: 188px;" src="../../assets/TA_Logo.png"/>
                        </td>
                        <td style="text-align: center; font-size: 15px; font-family: 'Calibri', sans-serif; after: float: right; width: 70%; border-right: 1px solid; border-left: 1px solid; border-top: 1px solid; border-bottom: 1px solid;">
                          <div>Jl. Bung Tomo No. 49 RT. 11 Telp/Fax . 0541 260521, Samarinda Seberang,</div>
                          <div>Email : hrga@trinityauto.id</div>
                        </td>
                      </tr>

                      <tr>
                        <td style="text-align: center; font-size: 18px; font-weight: bold; font-family: 'Calibri', sans-serif; padding-left: 10px; padding-right: 10px; after: float: left; width: 30%; border-right: 1px solid; border-left: 1px solid; border-top: 1px solid; border-bottom: 1px solid;">
                          <label>SURAT PERMOHONAN IZIN CUTI</label>
                        </td>

                        <td style="after: float: left; width: 70%; border-right: 1px solid; border-left: 1px solid; border-top: 1px solid; border-bottom: 1px solid;">
                          <table style="font-size: 14px; font-family: 'Calibri', sans-serif;">
                            <tr style="height: 20px;">
                              <td style="padding-left: 8px; after: float: left; width: 40%; border-right: 1px solid; border-left: none; border-top: none; border-bottom: 1px solid;"><label>No. Dokumen</label></td>
                              <td style="padding-left: 8px; after: float: right; width: 60%; border-right: none; border-left: 1px solid; border-top: none; border-bottom: 1px solid;"><label>FORMULIR HRD.12</label></td>
                            </tr>
                            <tr>
                              <td style="padding-left: 8px; after: float: left; width: 40%; border-right: 1px solid; border-left: none; border-top: 1px solid; border-bottom: none;"><label>Tanggal Dibuat</label></td>
                              <td style="padding-left: 8px; after: float: right; width: 60%; border-right: none; border-left: 1px solid; border-top: 1px solid; border-bottom: none;"><label>01 Maret 2016</label></td>
                            </tr>
                            <tr>
                              <td style="padding-left: 8px; after: float: left; width: 40%; border-right: 1px solid; border-left: none; border-top: 1px solid; border-bottom: none;"><label>Revisi</label></td>
                              <td style="padding-left: 8px; after: float: right; width: 60%; border-right: none; border-left: 1px solid; border-top: 1px solid; border-bottom: none;"><label>1</label></td>
                            </tr>
                            <tr>
                              <td style="padding-left: 8px; after: float: left; width: 40%; border-right: 1px solid; border-left: none; border-top: 1px solid; border-bottom: none;"><label>Halaman</label></td>
                              <td style="padding-left: 8px; after: float: right; width: 60%; border-right: none; border-left: 1px solid; border-top: 1px solid; border-bottom: none;"><label>1-1</label></td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <!-- Kepala Surat End -->

                  <!-- Isi Surat Strat -->
                  <div class="ml-3 mr-3">
                    <div>
                      <v-row no-gutters>
                        <label class="col-4 d-flex flex-row" v-text="'No : '+perusahaan+'/'+kode+'/'+tahun+'/'+no"></label>
                        <label class=" col-8 d-flex flex-row-reverse" v-text="'Samarinda, '+DibuatTgl"></label>
                      </v-row>
                    </div>

                    <div>
                      <v-row no-gutters>
                        <label>Sesuai kepentingannya, dengan ini diberikan izin cuti (<strong style="font-style: italic;">-TAHUNAN, -BERSALIN </strong>)* kepada karyawan/(ti) :</label>
                      </v-row>
                    </div>

                    <div>
                      <v-row no-gutters>
                        <v-col cols="9">
                          <div>
                            <v-row no-gutters>
                              <label style="width: 29%;">Nama</label>
                              <label style="width: 2%;">:</label>
                              <label style="width: 69%;" v-text="datacuti.length != 0 ? datacuti.karyawan.Nama : ''"></label>
                            </v-row>
                          </div>

                          <div>
                            <v-row no-gutters>
                              <label style="width: 29%;">NRK</label>
                              <label style="width: 2%;">:</label>
                              <label style="width: 69%;" v-text="datacuti.length != 0 ? datacuti.karyawan.NRK : ''"></label>
                            </v-row>
                          </div>

                          <div>
                            <v-row no-gutters>
                              <label style="width: 29%;">Awal Kerja</label>
                              <label style="width: 2%;">:</label>
                              <label style="width: 69%;" v-text="datacuti.length != 0 ? datacuti.karyawan.TglMasuk : ''"></label>
                            </v-row>
                          </div>

                          <div>
                            <v-row no-gutters>
                              <label style="width: 29%;">Jabatan</label>
                              <label style="width: 2%;">:</label>
                              <label style="width: 69%;" v-text="datacuti.length != 0 ? datacuti.karyawan.jabatan.NamaJabatan : ''"></label>
                            </v-row>
                          </div>

                          <div>
                            <v-row no-gutters>
                              <label style="width: 29%;">Keperluan Alasan</label>
                              <label style="width: 2%;">:</label>
                              <label style="width: 69%;" v-text="datacuti.length != 0 ? datacuti.AlasanCuti : ''"></label>
                            </v-row>
                          </div>

                          <div>
                            <v-row no-gutters>
                              <label style="width: 29%;">Mulai Tanggal</label>
                              <label style="width: 2%;">:</label>
                              <v-row no-gutters>
                                <label style="width: 21%;" v-text="datacuti.length != 0 ? datacuti.TanggalMulai : ''"></label>
                                <label style="width: 7%;">s/d</label>
                                <label style="width: 21%;" v-text="datacuti.length != 0 ? datacuti.TanggalSelesai : ''"></label>
                              </v-row>
                            </v-row>
                          </div>

                          <div>
                            <v-row no-gutters>
                              <label style="width: 29%;">Jumlah Cuti Diambil</label>
                              <label style="width: 2%;">:</label>
                              <v-row no-gutters>
                                <label style="width: 6%;" v-text="datacuti.length != 0 ? datacuti.JumlahCuti : ''"></label>
                                <label style="width: 22%;">Sisa Hak Cuti</label>
                                <label style="width: 6%;" v-text="datacuti.length != 0 ? datacuti.karyawan.Cuti : ''"></label>
                              </v-row>
                            </v-row>
                          </div>

                          <div>
                            <v-row no-gutters>
                              <label style="width: 29%;">Kembali bekerja tanggal</label>
                              <label style="width: 2%;">:</label>
                              <label style="width: 69%;"></label>
                            </v-row>
                          </div>
                        </v-col>

                        <v-col cols="3">
                          <div style="width: 152px; height: 152px; margin: auto; margin-top: 10px;">
                            <figure class="qrcode" v-show="this.datacuti.approvalcuti.length">
                              <qrcode
                                :value="'Tertanda '+datacuti"
                                tag="svg"
                                :options="{
                                  errorCorrectionLevel: 'Q',
                                  width: 150,
                                }"
                              ></qrcode>
                              <img
                                class="qrcode__image"
                                src="../../assets/LogoTrinity.png"
                                alt="Barcode"
                              />
                            </figure>
                          </div>
                        </v-col>
                      </v-row>
                    </div>

                    <div>
                      <v-row no-gutters>
                        <label>Demikian surat permohonan izin cuti ini kami berikan agar dapat dipergunakan sebagai mana mestinya.</label>
                      </v-row>
                    </div>
                  </div>
                  <!-- Isi Surat End -->

                  <!-- Approval Oleh Start -->
                  <div class="ma-3 text-center">
                    <table>
                      <tr>
                        <td style="width: 25%;">Dibuat Oleh :</td>
                        <td>Diperiksa Oleh :</td>
                        <td style="width: 25%;">Disetujui Oleh :</td>
                      </tr>
                    </table>
                    <table>
                      <tr>
                        <th style="width: 20%;">Reski Widhianto</th>
                        <th style="width: 20%;">Rudy Tjan</th>
                        <th style="width: 20%;">Alfian Malik</th>
                        <th style="width: 20%;">Herdaru Purwianto</th>
                      </tr>
                    </table>
                    <table>
                      <tr>
                        <td style="width: 20%;">Pemohon</td>
                        <td style="width: 20%;">Head Dept</td>
                        <td style="width: 20%;">HR</td>
                        <td style="width: 20%;">Opr Div Head</td>
                      </tr>
                    </table>
                  </div>
                  <!-- Approval Oleh End --> 
                </div>
              </v-sheet>
            </v-row>
          </v-container>
          <!-- <page size="A4" layout="landscape"></page> -->
        </v-card-text>
      </v-card>
</template>
<script>
import api from "@/services/http";
export default {
    data(){
        return{
            perusahaan:this.$route.params.perusahaan,
            kode:this.$route.params.kode,
            tahun:this.$route.params.tahun,
            no:this.$route.params.no,
            DialogLihatSuratCuti:false,
            DibuatTgl:'',
            dialoglistcuti:false,
            dialog_verifikasi_kontrak: false,
            mobile:null,
            datacuti:{
                NoCuti:'',
                karyawan:{
                    Nama:'',
                    jabatan:{
                        NamaJabatan:'',
                    }
                },
                approvalcuti:[]
            },
            detailcuti2:[],
            ShowValidasiKontrak:false,
            MasaKontrak:[],
            today:new Date().toJSON().slice(0,10).split('-').join('-'),
            windowSize: {x: 0, y: 0},
            dialog: false,
            token:localStorage.getItem('token'),
        }
    },
    mounted(){
      this.getcuti()
    },
    methods:{
      getcuti(){
        let data = JSON.parse(localStorage.getItem('user'))
        api.post('/cutilist/?token='+this.token,{
                KodeKaryawan:data.KodeKaryawan
              }).then(
                res => {
                  // console.log(res.data)
                  this.datacuti = res.data
                },
                err => {
                  console.log(err);
                }
              )
      },
    }
}
</script>
<style>
  table{
    border-collapse:collapse;
    width: 100%;
  }
  .qrcode {
  display: inline-block;
  /* font-size: 0;
  margin-bottom: 0; */
  position: relative;
}

.qrcode__image {
  background-color: #fff;
  border: 0.25rem solid #fff;
  border-radius: 3rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
  height: 19%;
  left: 25%;
  overflow: hidden;
  position: absolute;
  top: 48%;
  transform: translate(-50%, -50%);
  width: 10%;
}
</style>